import React from 'react'

import data from '../../data.json'
const { pics, names, bios } = data

export default function MiddleTop(
    { setShown, setClosed, influencerID }
) {
    return (
        <div className="top">
            <div className='mini'>
                <button className="back icon back2"
                    onClick={() => setShown(0)}>
                    <img src='back.svg' className='dots' height='16' />
                </button>

                <div className="profile">
                    <img src={pics[influencerID]} alt="" />
                    <div className="desc">
                        <div className="name">{names[influencerID]}</div>
                        <div className="bio">{bios[influencerID]} </div>
                    </div>
                </div>
            </div>
            <button style={{ border: "none" }} className="icon" onClick={() => {
                setClosed(e => !e)
                setShown(2)
            }}>
                <img src='dots.svg' className='dots' height='22' />
            </button>
        </div>
    )
}
