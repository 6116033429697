import React from 'react'

export default function Input(
    { setInp, inpRef, handleSubmit,
        inp, disabled, user }
) {
    return (
        <>
            <form className="bott" onSubmit={e => {
                e.preventDefault()
            }}>
                <input ref={inpRef} type="text" className='input'
                    value={inp} onChange={e => setInp(e.target.value)}

                    disabled={!user || !user.uid}

                    placeholder={
                        user && user.uid ?
                            'Type your message here...'
                            :
                            "You must log in to begin chatting..."
                    } />
                <div className="sendContainer">

                    <button className='send' disabled={disabled || !user || !user.uid} onClick={() => handleSubmit()}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="17" xmlns="http://www.w3.org/2000/svg"><path d="m476.59 227.05-.16-.07L49.35 49.84A23.56 23.56 0 0 0 27.14 52 24.65 24.65 0 0 0 16 72.59v113.29a24 24 0 0 0 19.52 23.57l232.93 43.07a4 4 0 0 1 0 7.86L35.53 303.45A24 24 0 0 0 16 327v113.31A23.57 23.57 0 0 0 26.59 460a23.94 23.94 0 0 0 13.22 4 24.55 24.55 0 0 0 9.52-1.93L476.4 285.94l.19-.09a32 32 0 0 0 0-58.8z"></path></svg>
                        Send</button>
                </div>
            </form>
        </>
    )
}
