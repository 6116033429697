import React, { useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { getDeviceId } from '../../utils/deviceId';
import { getEmailId } from '../../utils/email';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import History from './History';

import Header from '../global/Header';
import { AuthContext } from '../../AuthContextProvider';
import RightBar from './RightBar';
import TypingIndicator from './TypingIndicator';
import Models from './Models';
import Input from './Input';
import { getCurrentTime, getMessages } from '../../utils/helpers';
import MiddleTop from './MiddleTop';
import ChatLog from './ChatLog';
var lastInp;

export default function App() {

  const [messages, setMessages] = useState([
    // { content: "HI!", user: 0 }
  ])

  const [inp, setInp] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [testMode, setTestMode] = useState(
    // window.location.href.startsWith("http://localhost") || window.location.href.includes("192.168.")
    false
  )
  const [email, setEmail] = useState("no-email")
  const [deviceId, setDeviceId] = useState(null)
  const [closed, setClosed] = useState(false)
  const [shown, setShown] = useState(window.innerWidth <= 764 ? 1 : 2)
  const [sessionID, setSessionID] = useState(uuidv4())
  const user = useContext(AuthContext)
  const [newID, setNewID] = useState(true)
  const [influencerID, setInfluencerID] = useState(
    window.location.href.includes("aiguy") ?
      "aiguy" :
      "alex"
  )
  const [err, setErr] = useState(false)

  console.log(messages)

  useEffect(() => {
    if (!user) handlePen()
  }, [user])

  useEffect(() => {
    // console.log(sessionID)

  }, [sessionID])

  const inpRef = useRef()
  const recRef = useRef()
  const sendRef = useRef()
  const lastMessageRef = useRef(null);
  const prevMessagesRef = useRef([])
  const prevSessionID = useRef(uuidv4())

  useEffect(() => {
    // setReadMore(false)
    const temp = messages
    setMessages(prevMessagesRef.current)
    prevMessagesRef.current = [...temp]

    const t = sessionID
    const y = sessionID
    setSessionID(prevSessionID.current)
    prevSessionID.current = t

    // const x = uuidv4()
    // setSessionID(x)
    setNewID(true)
    lastMessageRef.current = y
  }, [influencerID])

  useEffect(() => {
    setDeviceId(getDeviceId())
    setEmail(getEmailId())
  }, [])

  useEffect(() => {
    if (messages.length) {

      const lastMessage = messages[messages.length - 1]

      if (lastMessage.user === 0 && lastMessageRef.current === sessionID && !newID) {
        // console.log(lastMessageRef.current, sessionID)
        recRef.current.play();
      }

      lastMessageRef.current = sessionID;

      // Reset input if last message is from the user (user === 1)
      if (lastMessage.user === 1) {
        setInp("");
      }
    }
  }, [messages])

  useEffect(() => {

    // if (!disabled) {
    animateScroll.scrollToBottom({
      containerId: "chatContainer",
      duration: 300,
      delay: 0,
      smooth: "easeIn",
    });
    // }

  }, [messages]);

  function fetchData(msg) {

    if (testMode) return new Promise((resolve) => setTimeout(resolve, 1000, {
      data: {
        'output_list': getMessages(msg)
      }
    }))

    else return axios.post(`https://api.alphatwin.ai/api/main`, {
      body: {
        'UUID': user?.uid,
        'influencer_id': influencerID,
        'new_id': newID,

        "session_id": sessionID,
        "question": msg,
        "device_id": deviceId,
        "email": email ? user?.email : null
      }
    })
  }

  useEffect(() => {
    if (messages.length && messages[messages.length - 1]?.user === 0 && disabled === sessionID) setDisabled(false)
  }, [messages])

  const callApi = msg => {
    // setUnreads(0)
    console.log("2")
    setDisabled(sessionID)
    fetchData(msg)
      .then(res => {
        console.log(3)
        if (res.data && res.data["output_list"]) {
          console.log(res.data)
          sendMessagesSequentially(res.data["output_list"])
            .then(() => {
              setDisabled(false)
              // setNum(n => n + 1)
              setTimeout(() => {
                inpRef.current && inpRef.current.focus();
              }, 100);
            })
        }
      })
      .catch(err => {
        setDisabled(false)
        var s;
        if (err && err.response && err.response.data && err.response.data.message) {
          s = err.response.data.message
        }
        else if (err && err.message) s = err.message
        else s = "Something went wrong!"
        // toast.error(s, { theme: "colored", position: "top-center" })
        console.error(err)
        setErr(true)
      })

    // const sendMessagesSequentially = async (messagesArray) => {
    //   if (user && user.uid) return;
    //   for (let i = 0; i < messagesArray.length; i++) {
    //     await new Promise((resolve) => setTimeout(resolve, 1000));
    //     setMessages(prevMessages => [...prevMessages, { user: 0, content: messagesArray[i], id: 9999999999 * Math.floor(Math.random()), time: getCurrentTime(), showTime: i === messagesArray.length - 1 }]);
    //     recRef.current.play();
    //     // setUnreads(u => u + 1)
    //   }
    // }
  }

  // const p = useRef()
  const latestSessionIDRef = useRef(sessionID);

  useEffect(() => {
    latestSessionIDRef.current = sessionID;
  }, [sessionID]);
  

  const handleSubmit = (retry = false) => {
    setErr(false);
    if (inp.length) lastInp = inp;
    if (disabled || (!retry && inp.trim().length === 0)) return;

    const newMessage = { content: retry ? lastInp : inp, user: 1, id: Date.now(), time: getCurrentTime(), showTime: true };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInp("");
    setDisabled(sessionID);
    const s = sessionID

    // try {
    fetchData(retry ? lastInp : inp)
      .then(response => {
        if (response.data && response.data["output_list"]) {
          sendMessagesSequentially(response.data["output_list"], s)
            .then(() => {
              setDisabled(false);
              setNewID(false);
              setTimeout(() => {
                inpRef.current && inpRef.current.focus();
              }, 100);
            })
        }
      })
      .catch(err => {
        setErr(true);
        console.error(err)
      })
    // } catch (error) {
    //   console.error(error);
    // } finally {

    // }
  };

  const sendMessagesSequentially = async (messagesArray, s) => {
    if (s !== latestSessionIDRef.current) return;
    for (let i = 0; i < messagesArray.length; i++) {
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      const newMessage = { user: 0, content: messagesArray[i].content, id: messagesArray[i].id, time: getCurrentTime(), showTime: i === messagesArray.length - 1 };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      recRef.current.play();
    }
  }

  useEffect(() => {
    if (window.innerWidth < 1300) setClosed(true)
  }, [])

  const handleHistClick = (c) => {
    setSessionID(c)
    setNewID(false)
    setShown(1)
  }

  const handlePen = () => {
    setNewID(true)
    const x = uuidv4()
    setSessionID(x)
    setMessages([])
    lastMessageRef.current = x
  }

  return (
    <>
      <audio src='B.mp3' ref={recRef} />
      <audio src='A.mp3' ref={sendRef} />

      <Header />

      <div className="body">
        <div className={`leftBar ${!closed && "closedOff"} ${shown === 0 ? "shown" : "notShown"}`}>
          <Models
            setShown={setShown} influencerID={influencerID}
            setInfluencerID={setInfluencerID} />

          <History setShown={setShown} handlePen={handlePen} influencerID={influencerID} setNewID={setNewID} sessionID={sessionID}
            setSessionID={setSessionID} user={user} handleHistClick={handleHistClick} />
        </div>

        <div className={`middleBar ${!closed && "closedOff"} ${shown === 1 ? "shown" : "notShown"}`}>
          <div className="card chat">
            <MiddleTop setShown={setShown} setClosed={setClosed} influencerID={influencerID} />
            <div className="convo" id="chatContainer">
              <ChatLog
                influencerID={influencerID}
                newID={newID} user={user} sessionID={sessionID}
                messages={messages} setMessages={setMessages}
                err={err} handleSubmit={handleSubmit} setInp={setInp} />
              {disabled === sessionID && <TypingIndicator />}
            </div>

            <Input setInp={setInp} inpRef={inpRef} user={user}
              handleSubmit={handleSubmit} inp={inp} disabled={disabled} />

          </div>
        </div>

        <RightBar
          closed={closed} influencerID={influencerID} setClosed={setClosed}
          shown={shown} setShown={setShown} testMode={testMode} setTestMode={setTestMode} />
      </div >
    </>
  )
}