import React, { useEffect, useState } from 'react'

import { auth, firestore } from '../../firebaseConfig'; // Adjust the import path to your Firebase config
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
    deleteDoc,
    doc,
    getDoc,
} from 'firebase/firestore';
import data from '../../data.json'
const { pics, names } = data
export default function History({
    sessionID, handleHistClick, user, influencerID,
    handlePen, setShown }) {

    const [chats, setChats] = useState([])
    const currentUserId = user?.uid

    useEffect(() => {

        if (!user) {
            setChats([])
            return;
        };

        const chatSessionsRef = collection(firestore, 'chatSessions');
        if (!currentUserId) {
            setChats([]);
            return;
        }

        const q = query(chatSessionsRef, where('userID', '==', currentUserId), where('influencerID', '==', influencerID), orderBy("lastMessageTimestamp", "desc"));

        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
                const c = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setChats(c);
            },
            (error) => {
                console.error('Error fetching messages:', error);
            }
        );

        return () => unsubscribe();

    }, [auth, user, influencerID])


    const handleDelete = async (id) => {
        const docRef = doc(firestore, "chatSessions", id)
        // if (docSnap.exists()) {
        await deleteDoc(docRef)
        handlePen()
        // }
    }

    return (
        <div className="card history" style={{ position: "relative" }}>
            <div className="text C" style={{
                display: "flex",
                alignItems: "center",
                gap: ".4em"
            }}>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"></path></svg>
                Chat History
            </div>
            <div className="icon pen" style={{ cursor: "pointer", zIndex: 99999999 }} onClick={() => {
                handlePen()
                setShown(1)
            }}>
                <img src="pen.svg" fill='#fff' className='dots' height="22" />
            </div>

            <div className='histContainer'>
                {

                    user && user.uid ?
                        chats.map(c =>
                            <div key={c.id} className={`profile hoverable ${sessionID === c.id && "selected"}`} onClick={() => handleHistClick(c.id)}
                                style={{ position: "relative" }}>
                                <img src={pics[c.influencerID]} alt="" />
                                <div className="desc">
                                    <div className="name">{names[c.influencerID]}</div>
                                    <div className="bio">{c.title}</div>
                                </div>
                                <div className='delete'>
                                    <div className="icon" style={{
                                        width: "22px",
                                        height: "22px",
                                    }} onClick={() => handleDelete(c.id)}>
                                        <svg stroke="#ADADAD" fill="#ADADAD" stroke-width="0" viewBox="0 0 448 512" width="12px" xmlns="http://www.w3.org/2000/svg"><path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
                                    </div>
                                </div>
                            </div>
                        )

                        :

                        <i className='italic'>You must log in to save chat history!</i>

                }
            </div>
        </div>
        // </div >
    )
}
