import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../AuthContextProvider';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { auth, firestore } from '../../firebaseConfig'; // Add firestore import
import { doc, onSnapshot } from 'firebase/firestore'; // For Firestore functions
import CheckoutButton from '../Chat/CheckoutButton';
import { Link } from 'react-router-dom';

const provider = new GoogleAuthProvider();

export default function Header() {

    const user = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState(null)
    const profileRef = useRef(null);

    // Function to set up real-time listener for user data
    const setupUserListener = (uid) => {
        try {
            const userDocRef = doc(firestore, 'users', uid);

            // Listen for real-time updates
            const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setUserData(userData)
                } else {
                    console.log("No such document!");
                }
            });

            return unsubscribe; // Return the unsubscribe function to stop listening later

        } catch (error) {
            console.error("Error setting up user listener: ", error);
        }
    }

    useEffect(() => {
        const handleMouseDown = (e) => { if (!profileRef.current?.contains(e.target)) setShow(false); };
        document.addEventListener('mousedown', handleMouseDown);

        let unsubscribe;
        // Set up real-time listener if user is logged in
        if (user?.uid) {
            unsubscribe = setupUserListener(user.uid);
        }

        return () => {
            document.removeEventListener('mousedown', handleMouseDown);

            // Unsubscribe from real-time updates when component unmounts or user changes
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, [user]);

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const u = result.user;
                // Setup real-time listener after login
                setupUserListener(u.uid);
            })
            .catch((error) => {
                console.error('Error during sign-in:', error);
            });
    };


    return (
        <div className="header">

            <a href='https://alphatwin.ai' className="logo">
                <img src="logo2.png" alt="logo"
                    height='40px' className="imgLogo" />
                AlphaTwin
            </a>

            <div className="welcome">Welcome <span className="username">{
                user?.displayName ?
                    user.displayName :
                    "New User"
            }</span> 👋</div>
            <div className='socials'>
                {/* {console.log(userData)} */}
                {
                    (userData?.subscriptionStatus !== 'Premium' || userData?.cancelAtPeriodEnd) &&
                    <CheckoutButton priceId={'price_1PxbK0KwYi8l6iBvrAFic2Oc'} />
                }
                {/* Subscribe */}
                <div style={{ position: "relative" }}>
                    <img src={
                        (user && user.photoURL) ?
                            user.photoURL : 'user.svg'
                    } className='user' width='40'
                        onClick={() => setShow(s => !s)} />
                    <div ref={profileRef} className={`dropdown ${show ? "show" : "noShow"}`}>
                        {user ?
                            <>
                                <div className="dropdownItem">
                                    <div className='notSignedIn'>{user.displayName}</div>
                                </div>

                                <div className="dropdownItem">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M223.75 130.75L154.62 15.54A31.997 31.997 0 0 0 127.18 0H16.03C3.08 0-4.5 14.57 2.92 25.18l111.27 158.96c29.72-27.77 67.52-46.83 109.56-53.39zM495.97 0H384.82c-11.24 0-21.66 5.9-27.44 15.54l-69.13 115.21c42.04 6.56 79.84 25.62 109.56 53.38L509.08 25.18C516.5 14.57 508.92 0 495.97 0zM256 160c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm92.52 157.26l-37.93 36.96 8.97 52.22c1.6 9.36-8.26 16.51-16.65 12.09L256 393.88l-46.9 24.65c-8.4 4.45-18.25-2.74-16.65-12.09l8.97-52.22-37.93-36.96c-6.82-6.64-3.05-18.23 6.35-19.59l52.43-7.64 23.43-47.52c2.11-4.28 6.19-6.39 10.28-6.39 4.11 0 8.22 2.14 10.33 6.39l23.43 47.52 52.43 7.64c9.4 1.36 13.17 12.95 6.35 19.59z"></path></svg>
                                    <Link to='/subscription' style={{ all: "unset" }}>
                                        <span className={`${userData?.subscriptionStatus === 'Basic' ? "" : "username"}`} style={{ color: userData?.subscriptionStatus === 'Basic' ? "#58009F" : "" }}>
                                            {userData?.subscriptionStatus}
                                        </span>
                                        <span style={{ marginLeft: ".2em" }}>
                                            Plan
                                        </span>
                                    </Link>
                                </div>

                                <div className="dropdownItem">
                                    {userData?.tokenCount} Tokens
                                </div>

                                <div className="dropdownItem" onClick={() => {
                                    signOut(auth)
                                        .then(() => {
                                            console.log("User signed out successfully");
                                        })
                                        .catch((error) => {
                                            console.error("Error signing out: ", error);
                                        });
                                }}>
                                    <img src="logout.svg" width={'18'} />
                                    <span>
                                        Logout
                                    </span>

                                </div>
                            </> : <>
                                <div className="dropdownItem">
                                    <div className='notSignedIn'>Not Signed In</div>
                                </div>
                                <div className="dropdownItem" onClick={signInWithGoogle}>
                                    <img src="google.svg" width={'18'} />
                                    <span>
                                        Google Login
                                    </span>
                                </div>
                                <div className="dropdownItem">
                                    <img src="info.svg" width={'18'} />
                                    <a target="_blank" href='https://alphatwin.ai' style={{ all: 'unset' }}>
                                        Learn More
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
